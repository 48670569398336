import { Client } from '@/models/Client'

export class Group {
  clients: Client
  title: string
  description: string
  // eslint-disable-next-line camelcase
  user_id: string

  constructor(data: any = {}) {
    this.clients = data.clients || []
    this.user_id = data.user_id || null
    this.title = data.title || null
    this.description = data.description || null
  }
}
