





































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  errorMsg,
  getTranslation,
  successMsg, validateForm
} from '@/utils'
import { saveGroup, addClients } from '@/api/groups'
import { getRemoteClients } from '@/api/helpers'
import { AppModule, DeviceType } from '@/store/modules/app'
import { Form } from 'element-ui'

@Component({
  name: 'GroupDetail'
})

export default class extends Vue {
  @Prop({ required: true }) private show!: boolean
  @Prop({ required: true }) private group!: any
  private getTranslation = getTranslation
  private clientsLoading = false;
  private clients = [];

  private rules = {
    title: [{
      required: true,
      trigger: 'blur'
    }]
  }

  get title() {
    if (this.group?.id) {
      return this.$t('groupDetail.editGroup')
    }
    return this.$t('groupDetail.newGroup')
  }

  get drawerSize() {
    if (AppModule.device === DeviceType.Desktop) {
      return 40
    }
    return 100
  }

  private handleClose() {
    this.$emit('close')
  }

  private async getRemoteClientsList(query: string) {
    this.clientsLoading = true
    this.clients = await getRemoteClients(query)
    this.clientsLoading = false
  }

  private onOpen() {
    this.clients = this.group.clients || []
  }

  private async saveItem() {
    const [valid] = await validateForm(this.$refs.groupForm as Form)
    if (!valid) return errorMsg('form.formErrors')
    try {
      const group = JSON.parse(JSON.stringify(this.group))
      group.clients = []
      const { data } = await saveGroup(group)
      await addClients({
        source_id: data.id,
        relation_ids: this.group.clients.map((client: any) => {
          return client.id
        })
      })
      await successMsg('groupList.successSaveGroup')
      this.$emit('close', true)
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }
}
