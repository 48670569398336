
















































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  CollectionRequestMeta,
  createFilter,
  getSortItems, ignoreCaseSearchField,
  prepareListFilters
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import GroupDetail from './components/GroupDetail.vue'
import {
  deleteGroup,
  deleteGroupBulk,
  exportGroups,
  exportGroupsWithClients,
  getGroup,
  getGroups
} from '@/api/groups'
import {
  confirmDialog,
  debounce,
  errorMsg,
  hasPermission,
  parseTime,
  successMsg
} from '@/utils'
import { Group } from '@/models/Group'
import { AppModule, DeviceType } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import { getLocale } from '@/lang'

@Component({
  name: 'GroupList',
  components: {
    Pagination,
    ListToolbar,
    GroupDetail
  }
})

export default class extends Vue {
  private total = 0
  private list = []
  private listLoading = true
  private editDrawer = false
  private selected: any = []
  private listQuery: CollectionRequestMeta = createFilter()
  private group: Group | null = null
  private search = ''
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private hasPermission = hasPermission
  private searchFields = [ignoreCaseSearchField('title')]

  created() {
    this.getList()
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private addNewItem() {
    this.group = new Group({
      user_id: UserModule.id
    })
    this.editDrawer = true
  }

  private async editItem(group: any) {
    try {
      const { data } = await getGroup({
        id: group.id,
        withClients: true
      })
      this.group = data
      this.editDrawer = true
    } catch (err) {}
  }

  private onCloseDrawer(refreshList: boolean) {
    this.editDrawer = false
    this.group = null
    refreshList && this.getList()
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteGroup({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deleteGroupBulk(this.selected.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportGroups(this.selected)
        break
      }
      case 'download_with_clients': {
        exportGroupsWithClients({
          ids: this.selected.map((item: any) => {
            return item.id
          }),
          locale: getLocale()
        }).then((res: any) => {
          const url = URL.createObjectURL(new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'groups-with-clients.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        break
      }
      case 'create': {
        this.addNewItem()
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      prepareListFilters({
        listQuery: this.listQuery,
        withDefaultSort: true,
        search: this.search,
        searchFields: this.searchFields
      })
      const { data } = await getGroups(this.listQuery)
      this.list = data.collection
      this.total = data.pagination.total_items
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
    this.debounceMethod()
  }
}
