import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson } from '@/utils'
import { createFilter, getSearchFilter } from '@/utils/filter'

export const getGroups = (data: any) =>
  request({
    method: 'post',
    url: '/api/groups/getGroups',
    data
  })

export const getGroup = (params: any) =>
  request({
    method: 'get',
    url: '/api/groups/getGroupLight',
    params
  })

export const saveGroup = (data: any) =>
  request({
    method: 'post',
    url: '/api/groups/saveGroup',
    data
  })

export const addClients = (data: any) =>
  request({
    method: 'post',
    url: '/api/groups/addClients',
    data
  })

export const removeClients = (data: any) =>
  request({
    method: 'post',
    url: '/api/groups/removeClients',
    data
  })

export const saveGroupBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/groups/saveGroupBulk',
    data
  })

export const deleteGroup = (params: any) =>
  request({
    method: 'get',
    url: '/api/groups/deleteGroup',
    params
  })

export const deleteGroupBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/groups/deleteGroupBulk',
    data
  })

export const exportGroupsWithClients = (data: any) =>
  request({
    method: 'post',
    url: '/api/groups/exportGroups',
    responseType: 'blob',
    data
  })

export const getRemoteGroups = async(query: string, items: any = []) => {
  try {
    if (!query) return []
    const { data } = await getGroups(createFilter([getSearchFilter(
      query, [{
        key: 'title',
        options: {
          ignore_case: true,
          unaccent: true
        }
      }]
    ), ...items]))
    return data.collection
  } catch (err) {
    return []
  }
}

export const exportGroups = (groups: any) => {
  const fields = [
    {
      key: 'title',
      type: 'translations',
      transKeys: ['title']
    },
    {
      key: 'date_created',
      type: 'timestamp'
    },
    {
      key: 'date_updated',
      type: 'timestamp'
    }
  ]
  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, groups),
    'group-list'
  )
}
